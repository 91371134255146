import { useState } from "react";
import { useInView } from "react-hook-inview";
import IamImg from "../images/iam.png";
import { ReactComponent as Line } from "../images/Line.svg";

export default function About() {
    const [openText, setOpenText] = useState(false);

    const [line, isLineVisible] = useInView({
        threshold: 0.5,
    });
    return (
        <>
            <div className="about">
                <div className="content" ref={line}>
                    <div className={`line ${isLineVisible && "drawLine"}`}>
                        <Line />
                    </div>
                    <div className="left">
                        <h1>Про мене</h1>
                        <div className={`text ${openText ? "open-text" : ""}`}>
                            {/* 232 */}
                            <p>
                                Для мене юриспруденція та адвокатура – це життя,
                                а не просто бізнес, де для мене люди, які
                                звертаються до мене за допомогою не просто
                                Клієнти, а мої Друзі! Я ніколи не уявляла та не
                                уявляю себе в іншій сфері діяльності! Потрібно
                                бути по-справжньому закоханим у свою роботу та
                                отримувати від неї справжнє задоволення.
                            </p>
                            <p>
                                Юлія Олексіївна Балюк народилася 10 липня 1976
                                року. Закінчила Київський Національний
                                Університет ім. Т.Г. Шевченка (Магістр права,
                                Україна) та Університет ім. Мартіна Лютера
                                Галле-Віттенберг (Юридичний факультет,
                                Німеччина).
                            </p>
                            <p>
                                Автор 21 наукової публікації, монографії,
                                захистила кандидатську дисертацію на тему
                                «Нерухомість як об’єкт права спільної власності
                                фізичних осіб».
                            </p>
                            <p>
                                Адвокат та юрист з величезним досвідом та
                                солідним стажем, керівник судової практики
                                юридичної компанії «МІКО», член правління
                                «Асоціації юристів України», член
                                торговельно-промислової палати Києва та України,
                                визнана найкращим генеральним директором у сфері
                                юриспруденції у 2011 році за версією журналу «
                                Генеральний директор», є постійним експертом шоу
                                «Україна говорить» та «Стосується кожного».
                            </p>
                            <p>Її заслуги відзначені:</p>
                            <ul>
                                <li>
                                    ювілейною нагородою з нагоди 20-ї
                                    Внутрішньої Безпеки Міністерства внутрішніх
                                    справ України;
                                </li>
                                <li>
                                    нагородою асоціації юристів України «За
                                    ефективне управління»;
                                </li>
                                <li>
                                    золотою медаллю Міжнародної кадрової
                                    Академії «За ефективне управління»;
                                </li>
                                <li>
                                    орденом Української Православної Церкви
                                    святої великомучениці Варвари II ступеня.
                                </li>
                            </ul>
                            <p>
                                З 1998 року є творцем та генеральним директором
                                однієї з найнадійніших та популярних юридичних
                                компаній України – ТОВ «Юридична компанія
                                «МІКО». З 2015 року викладає в Університеті
                                Драгоманова (Київ, Україна).
                            </p>
                            <p>
                                Життєве кредо Юлії Балюк: <br />
                                -Для того, щоб стати успішним адвокатом та
                                юристом, потрібно дуже цього хотіти, і знати, як
                                цього досягти. Не важливо, чим мріє займатися
                                людина, головне – робити це добре і якісно. Це
                                основне у кар’єрі будь-якої людини. Так сталося
                                і в моєму житті, я завжди знала чого хочу
                                досягти й чим займатися, і йшла до цієї мети
                                навмисно, попри будь-які складнощі та перешкоди.
                            </p>
                            <p>
                                -Для мене юриспруденція та адвокатура – це
                                життя, а не просто бізнес, де для мене люди, які
                                звертаються до мене за допомогою не просто
                                Клієнти, а мої Друзі! Я ніколи не уявляла та не
                                уявляю себе в іншій сфері діяльності! Потрібно
                                бути по-справжньому закоханим у свою роботу та
                                отримувати від неї справжнє задоволення.
                            </p>
                        </div>

                        <button
                            className="secondary"
                            onClick={() => {
                                setOpenText(!openText);
                            }}
                        >
                            {openText
                                ? "Приховати інформацію"
                                : "Більше інформації"}
                        </button>
                    </div>
                    <div className="right">
                        <img src={IamImg} alt="IamImg" />
                    </div>
                </div>
            </div>

            <div className="about-plus">
                <div className="content">
                    <div className="block">
                        <div className="head">20 </div>
                        <div className="text">років досвіду</div>
                    </div>
                    <div className="block">
                        <div className="head">96%</div>
                        <div className="text">виграних справ</div>
                    </div>
                    <div className="block">
                        <div className="head">100+</div>
                        <div className="text">закінчених справ щорічно</div>
                    </div>
                </div>
            </div>
        </>
    );
}
