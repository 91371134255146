



export default function Deals() {
    

    return(
        <div className="deals">
            <div className="content">
                <h1>Останні завершені справи</h1>

                <div className="blocks">
                    <div className="block">
                        <div className="top">
                            <div className="head">Стягнення заборгованості</div>
                            <div className="text">
                            Організація “М” здійснила поставку товару за договором, але не отримала оплату за них. Від оплати контрагент ухилявся під різними приводами. Для вирішення питання необхідно було звертатися до суду
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="head">Рішення</div>
                            <div className="text">За підсумками процесу суд виніс рішення яким стягнув із відповідача заявлені відсотки за користування чужими коштами та оплату судових витрат у повному розмірі</div>
                        </div>
                    </div>


                    <div className="block">
                        <div className="top">
                            <div className="head">Повернення неякісного товару</div>
                            <div className="text">
                            Сімейна пара замовила у підприємця виготовлення кухонної гарнітури за 64 000 грн. Кухня була виготовлена ​​з порушенням термінів, а під час монтажу виявились численні недоліки.
Замовники звернулися до ІП з претензією, але не отримали відповіді. Було прийняте рішення звернутися до суду.
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="head">Рішення</div>
                            <div className="text">Рішенням суду позовні вимоги були задоволені, з відповідача було стягнуто повну вартість кухні, компенсацію моральної шкоди, повну оплату юридичних послуг.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}